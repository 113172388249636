import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InspectionCountTable from '../InspectionCountTable';
import InspectionTable from '../InspectionTable';
import { useAuthenticated } from 'react-admin';
import dotenv from 'dotenv';

// Carga las variables de entorno desde el archivo .env
dotenv.config();

export default function Content4({onMenu}) {

    useAuthenticated();
    const [inspections, setInspections] = useState([]);
    const [total, setTotal] = useState([0]);
    const [counts, setCounts] = useState({
        completed: 0,
        pending: 0,
        canceled: 0,
        failed: 0
    });

    const [salesData, setSalesData] = useState({
        totalSales: '$...',
        salesIncrease: '...%',
        sinceLastMonth: 'Desde último mes',
        salesChart: {} // Aquí podrías inicializar con datos del gráfico si es necesario
    });

    useEffect(() => {
        const fetchInspections = async () => {
            let totalSum = 0;
            const apiUrl = 'https://e0ushf3cpe.execute-api.us-east-1.amazonaws.com/Prod/stackMainInfra-TransactionsFunction-1iBIwwFNi5XJ';
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            };

            try {

                const response = await axios.post(apiUrl, config);
                const datasinfiltrar = response.data;

                const counters = {
                    completed: datasinfiltrar.filter(item => item.estado === 'completed').length,
                    pending: datasinfiltrar.filter(item => item.estado === 'pending').length,
                    canceled: datasinfiltrar.filter(item => item.estado === 'canceled').length,
                    failed: datasinfiltrar.filter(item => item.estado === 'failed').length
                };
                setCounts(counters);

                const data = datasinfiltrar.filter(item => item.estado === 'failed');
                setInspections(data);
                if (data.length > 0) { // Verifica que haya datos en 'data'
                    data.forEach(item => {
                        if (item.tarifas.length > 0)
                            totalSum += item.tarifas[0].monto;
                    });
                    setTotal(totalSum);
                } else {
                    console.log('El arreglo de inspecciones está vacío');
                }

                setTimeout(() => {
                    setSalesData({
                        ...salesData,
                        totalSales: `${totalSum.toLocaleString('es-CL', {
                            style: 'currency',
                            currency: 'CLP'
                        })}`,
                        salesIncrease: '45.8%',
                        sinceLastMonth: 'Desde último 2 meses' // Ejemplo de datos actualizados
                    });
                }, 2000);
            } catch (error) {
                console.error('Error fetching inspections:', error);
            }
        };
        fetchInspections();
    }, []); 

    return (
        <div className="content-wrapper">

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3><InspectionCountTable count={counts.completed} /></h3>
                                    <p>Completadas   </p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-android-clipboard"></i>
                                </div>
                                <a href="/" className="small-box-footer" onClick={() => onMenu('Principal')}>Mas info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3><InspectionCountTable count={counts.pending} /></h3>
                                    <p>Pendientes</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-android-clipboard"></i>
                                </div>
                                <a href="/" className="small-box-footer" onClick={() => onMenu('Pending')}>Mas info<i className="fas fa-arrow-circle-right"></i></a>
                                {/* onClick={onSelectStatus(['pending'])} */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-gray">
                                <div className="inner">
                                    <h3><InspectionCountTable count={counts.canceled} /></h3>
                                    <p>Canceladas</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-android-clipboard"></i>
                                </div>
                                <a href="/" className="small-box-footer" onClick={() => onMenu('Canceled')}>Mas info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3><InspectionCountTable count={counts.failed} /></h3>
                                    <p>Fallidas</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-android-clipboard"></i>
                                </div>
                                <a href="/" className="small-box-footer" onClick={() => onMenu('Failed')}>Mas info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            {/* Card Visitor */}
                            <div className="card">
                                <div className="card-header border-0">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title">Visitas en linea</h3>
                                        <a href="javascript:void(0);">Ver Reporte</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <p className="d-flex flex-column">
                                            <span className="text-bold text-lg">820</span>
                                            <span>Transcurso de Visitas</span>
                                        </p>
                                        <p className="ml-auto d-flex flex-column text-right">
                                            <span className="text-success">
                                                <i className="fas fa-arrow-up" /> 12.5%
                                            </span>
                                            <span className="text-muted">Desde Última Semana</span>
                                        </p>
                                    </div>
                                    {/* /.d-flex */}
                                    <div className="position-relative mb-4">
                                        <canvas id="visitors-chart" height={200} />
                                    </div>
                                    <div className="d-flex flex-row justify-content-end">
                                        <span className="mr-2">
                                            <i className="fas fa-square text-primary" /> Esta Semana
                                        </span>
                                        <span>
                                            <i className="fas fa-square text-gray" /> Última Semana
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Ventas Card */}
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header border-0">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title">Ventas</h3>
                                        <a href="javascript:void(0);">Ver Reporte</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <p className="d-flex flex-column">
                                            <span className="text-bold text-lg">{salesData.totalSales}</span>
                                            <span>Ventas transcurridas</span>
                                        </p>
                                        <p className="ml-auto d-flex flex-column text-right">
                                            <span className="text-success">
                                                <i className="fas fa-arrow-up" /> {salesData.salesIncrease}
                                            </span>
                                            <span className="text-muted">Desde el último Mes</span>
                                        </p>
                                    </div>
                                    {/* /.d-flex */}
                                    <div className="position-relative mb-4">
                                        <canvas id="sales-chart" height={200} />
                                    </div>
                                    <div className="d-flex flex-row justify-content-end">
                                        <span className="mr-2">
                                            <i className="fas fa-square text-primary" /> Este Año
                                        </span>
                                        <span>
                                            <i className="fas fa-square text-gray" /> Último Año
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {/* Tabular Trx */}
                            <InspectionTable inspections={inspections} />
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
