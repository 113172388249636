import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dotenv from 'dotenv';
import SuscriptorsUserTable from '../SuscriptorsUserTable';
import { useAuthenticated } from 'react-admin';

// Carga las variables de entorno desde el archivo .env
dotenv.config();

export default function ContentSetting() {

    useAuthenticated();
    const [recipientsUser, setRecipientsUser] = useState([]);

    // Asegúrate de que las variables de entorno estén definidas correctamente
    const apiHost = process.env.REACT_APP_HOST;
    const apiPortUser = process.env.REACT_APP_PORT_LOGIN_SERVICE;

    useEffect(() => { 

        const fetchUserRecipients = async () => {

            const apiUrl = `http://${apiHost}:${apiPortUser}/api/users`;

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get(apiUrl, config);
            setRecipientsUser(response.data);
        };

        fetchUserRecipients();
        const interval1 = setInterval(fetchUserRecipients, 5000); // Poll every 5 seconds


        return () => {
            clearInterval(interval1);
        };
    }, [apiHost, apiPortUser]); //
    return (
        <div className="content-wrapper">

            <div className="content">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-lg-12">
                            {/* /.card */}
                            <SuscriptorsUserTable recipients={recipientsUser} />
                        </div>
                        {/* /.col-md-6 */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </div>
        </div>
    )
}
