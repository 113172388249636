import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './components/Header';
import Aside from './components/Aside';
import Content from './components/Content';
import Content2 from './components/Content2';
import Content3 from './components/Content3';
import Content4 from './components/Content4';
import ContentSetting from './components/ContentSetting';
import Footer from './components/Footer';
import MyLoginPage from './pages/MyLoginPage';

const Home = () => (
    <div>
        <h1>Welcome to Home</h1>
        <p>This is the home page content.</p>
    </div>
);

const queryClient = new QueryClient();

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        return localStorage.getItem('isLoggedIn') === 'true';
    });

    // Menu Aside
    const [isSettingIn, setIsSettingIn] = useState(() => {
        return localStorage.getItem('isSettingIn') === 'true';
    });

    // Menu Content Counters Choice
    const [isInspectionIn, setInspectionIn] = useState(() => {
        return localStorage.getItem('isInspectionIn') === 'true';
    });
    const [isPending, setPending] = useState(() => {
        return localStorage.getItem('isPending') === 'true';
    });
    const [isCanceled, setCanceled] = useState(() => {
        return localStorage.getItem('isCanceled') === 'true';
    });
    const [isFailed, setFailed] = useState(() => {
        return localStorage.getItem('isFailed') === 'true';
    });


    const [selectedMenu, setSelectedMenu] = useState('Principal');

    // const [selectedStatus, setSelectedStatus] = useState([]);

    const handleLogin = () => {

        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        setInspectionIn(true);
        localStorage.setItem('isInspectionIn', 'true');
        // Por defecto la lista de las transacciones completadas son las que se visualizaran
        setPending(false);
        localStorage.setItem('isPending', 'false');
        setIsSettingIn(false);
        localStorage.setItem('isCanceled', 'false');
        setCanceled(false);
        localStorage.setItem('isFailed', 'false');
        setFailed(false);
        // La opcion por defecto que se visualizara es Principal
        setSelectedMenu("Principal");

    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.setItem('isLoggedIn', 'false');
        setIsSettingIn(false);
        localStorage.setItem('isSettingIn', 'false');
        localStorage.removeItem('isSettingIn');
        setInspectionIn(false);
        localStorage.removeItem('isPending');
        setPending(false);
        localStorage.removeItem('isCanceled');
        setCanceled(false);
        localStorage.removeItem('isFailed');
        setFailed(false);
        localStorage.setItem('isInspectionIn', 'false');
        localStorage.removeItem('isInspectionIn');
    };

    const handleMenuSelection = (menu) => {
        // Lógica adicional para manejar la selección del menú
        setSelectedMenu(menu);
        switch (menu) {
            case "Principal":
                setInspectionIn(true);
                localStorage.setItem('isInspectionIn', 'true');
                localStorage.removeItem('isPending');
                setPending(false);
                localStorage.removeItem('isCanceled');
                setCanceled(false);
                localStorage.removeItem('isFailed');
                setFailed(false);
                localStorage.removeItem('isSettingIn');
                setIsSettingIn(false);
                break;
            case "Pending":
                setPending(true);
                localStorage.setItem('isPending', 'true');
                localStorage.removeItem('isInspectionIn');
                setInspectionIn(false);
                localStorage.removeItem('isCanceled');
                setCanceled(false);
                localStorage.removeItem('isFailed');
                setFailed(false);
                localStorage.removeItem('isSettingIn');
                setIsSettingIn(false);
                break;
            case "Canceled":
                setCanceled(true);
                localStorage.setItem('isCanceled', 'true');
                localStorage.removeItem('isInspectionIn');
                setInspectionIn(false);
                localStorage.removeItem('isPending');
                setPending(false);
                localStorage.removeItem('isFailed');
                setFailed(false);
                localStorage.removeItem('isSettingIn');
                setIsSettingIn(false);
                break;
            case "Failed":
                setFailed(true);
                localStorage.setItem('isFailed', 'true');
                localStorage.removeItem('isInspectionIn');
                setInspectionIn(false);
                localStorage.removeItem('isPending');
                setPending(false);
                localStorage.removeItem('isCanceled');
                setCanceled(false);
                localStorage.removeItem('isSettingIn');
                setIsSettingIn(false);
                break;
            case "Setting":
                setIsSettingIn(true);
                localStorage.setItem('isSettingIn', 'true');
                localStorage.removeItem('isInspectionIn');
                setInspectionIn(false);
                localStorage.removeItem('isPending');
                setPending(false);
                localStorage.removeItem('isCanceled');
                setCanceled(false);
                localStorage.removeItem('isFailed');
                setFailed(false);
                break;
            default:
                break;
        }
    };


    useEffect(() => {
        if (isLoggedIn) {
            localStorage.setItem('isLoggedIn', 'true');
        } else {
            localStorage.removeItem('isLoggedIn');
        }
    }, [isLoggedIn]);

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <div className="App">
                    <Routes>
                        <Route
                            path="/login"
                            element={<MyLoginPage onLogin={handleLogin} />}
                        />
                        <Route
                            path="/"
                            element={
                                isLoggedIn ? (
                                    <>
                                        <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} selectedMenu={selectedMenu} />
                                        <Aside onMenu={handleMenuSelection} selectedMenu={selectedMenu} />
                                        {
                                            isSettingIn ? <ContentSetting /> :
                                                isInspectionIn ? <Content onMenu={handleMenuSelection} /> :
                                                    isPending ? <Content2 onMenu={handleMenuSelection} /> : 
                                                    isCanceled ? <Content3 onMenu={handleMenuSelection} /> : 
                                                    isFailed ? <Content4 onMenu={handleMenuSelection} /> : null
                                        }
                                    </>
                                ) : (
                                    <Navigate to="/login" />
                                )
                            }
                        />
                        <Route path="/home" element={<Home />} />
                    </Routes>
                    <Footer />
                </div>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
