import './InspectionCountTable.css';



function InspectionCountTable({ count }) {  
  return (
    (count)
  );
}

export default InspectionCountTable;
