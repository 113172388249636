import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table'; 
import './InspectionTable.css';



function InspectionTable({ inspections, setInspections }) {

  // Define las columnas para react-table
  const columns = React.useMemo(
    () => [
      { Header: 'Entidad', accessor: 'entidad' }, 
      { Header: 'Tenant', accessor: 'tenant_nombre' }, 
      { Header: 'Cliente Rut', accessor: 'cliente_rut' }, 
      { Header: 'Cliente', accessor: 'cliente_nombre' }, 
      { Header: 'Mail', accessor: 'cliente_email' }, 
      { Header: 'Servicio', accessor: 'servicio_descripcion' },    
      { Header: 'Producto', accessor: 'producto_descripcion' },
      { Header: 'Tarifa($)', accessor: 'tarifas[0].monto' },
      { Header: 'Fecha Mov.', accessor: 'fecha_movimiento' },
      { Header: 'Estado', accessor: 'estado' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize }
  } = useTable(
    { columns, data: inspections, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );

  // Componente de tabla con paginación y ordenamiento
  return (
    <div className="card">
      <div className="card-header border-0">
        <h3 className="card-title">Eventos Reportados</h3>
        <div className="card-tools">
          <a href="/" className="btn btn-tool btn-sm">
            <i className="fas fa-download" />
          </a>
          <a href="/" className="btn btn-tool btn-sm">
            <i className="fas fa-bars" />
          </a>
        </div>
      </div>
      <div >
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td  {...cell.getCellProps()}>
                    {cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <div>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Anterior
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Siguiente
        </button>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map(size => (
            <option key={size} value={size}>
              Mostrar {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default InspectionTable;
