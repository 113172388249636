import React   from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuthenticated } from 'react-admin'; 
 

const Header = ({ isLoggedIn, onLogout, selectedMenu }) => {
    useAuthenticated();
    const navigate = useNavigate();
    // Asegúrate de que las variables de entorno estén definidas correctamente
    const apiHost = process.env.REACT_APP_HOST;
    const apiPortUser = process.env.REACT_APP_PORT_LOGIN_SERVICE; 
    const titles = {
        "Principal": "Home",
        "Setting": "Setting",
        "Content2": "DashBoard v2",
        "Content3": "DashBoard v3",
    };

    const handleLogin = async () => {
        navigate('/login');
    }

    const handleLogout = () => {
        // Elimina toda la información del almacenamiento local
        localStorage.clear();
    
        // Elimina toda la información del almacenamiento de sesión
        sessionStorage.clear();
    
        // Elimina el caché del navegador (solo si estás usando Service Workers)
        if ('caches' in window) {
            caches.keys().then((cacheNames) => {
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
            });
        } 
        // Redirige a la URL externa
        window.location.href = 'https://mycreditscore.cl';
    };

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a href="/" className="nav-link" data-widget="pushmenu"  role="button"><i className="fas fa-bars" /></a>
                </li>

                <li className="nav-item d-none d-sm-inline-block">
                    <a href="/" className="nav-link">{titles[selectedMenu]}</a>
                </li>
                {/* <li className="nav-item d-none d-sm-inline-block">
                    <a href="#" className="nav-link">Contact</a>
                </li> */}
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Navbar Search */}
                <li className="nav-item">
                    <a className="nav-link" data-widget="navbar-search" href="/" role="button">
                        <i className="fas fa-search" />
                    </a>
                    <div className="navbar-search-block">
                        <form className="form-inline">
                            <div className="input-group input-group-sm">
                                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                                <div className="input-group-append">
                                    <button className="btn btn-navbar" type="submit">
                                        <i className="fas fa-search" />
                                    </button>
                                    <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li> 
                {/* Notifications Dropdown Menu */}
                <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="/">
                        <i className="far fa-bell" />
                        <span className="badge badge-warning navbar-badge">15</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item dropdown-header">15 Notifications</span>
                        <div className="dropdown-divider" />
                        <a href="/" className="dropdown-item">
                            <i className="fas fa-envelope mr-2" /> 4 new messages
                            <span className="float-right text-muted text-sm">3 mins</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="/" className="dropdown-item">
                            <i className="fas fa-users mr-2" /> 8 friend requests
                            <span className="float-right text-muted text-sm">12 hours</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="/" className="dropdown-item">
                            <i className="fas fa-file mr-2" /> 3 new reports
                            <span className="float-right text-muted text-sm">2 days</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="/" className="dropdown-item dropdown-footer">See All Notifications</a>
                    </div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="/" role="button">
                        <i className="fas fa-expand-arrows-alt" />
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="/" role="button">
                        <i className="fas fa-th-large" />
                    </a>
                </li>
                {isLoggedIn ? (

                    <li className="nav-item">

                        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="https://mycreditscore.cl" role="button">
                            <button onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt" />
                            </button>
                        </a>

                    </li>

                ) : (

                    <li className="nav-item">
                        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="/" role="button">
                            <button onClick={handleLogin}> <i className="fas fa-sign-in-alt" />  </button>
                        </a>
                    </li>

                )}
            </ul>
        </nav>

    )
}

export default Header;
