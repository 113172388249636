import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Box, Typography } from '@mui/material'; 
import { useAuthenticated } from 'react-admin';
import dotenv from 'dotenv'; 

// Carga las variables de entorno desde el archivo .env
dotenv.config();

const MyLoginPage = ({ onLogin }) => {

    useAuthenticated(); 
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // Asegúrate de que las variables de entorno estén definidas correctamente
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // Captura los parámetros de la URL
        const params = new URLSearchParams(window.location.search);
        const usernameFromUrl = params.get('username');
        const passwordFromUrl = params.get('password');

        // Si existen, los coloca en el estado
        if (usernameFromUrl && passwordFromUrl) {
            setUsername(usernameFromUrl);
            setPassword(passwordFromUrl);
            handleLogin(usernameFromUrl, passwordFromUrl); // Ejecuta el login automáticamente
        }
    }, []);

    const handleLogin = async (username, password) => {
        try {
            const response = await axios.post(`${apiUrl}/api/login`, {
                username,
                password
            });
            console.log("Depuración");
            console.log(response, JSON.stringify(response.status));
            if (response.status === 200) {
                const data = response.data;
                console.log("data:", JSON.stringify(data));
                localStorage.setItem('user', JSON.stringify(data));
                onLogin(); // Llama a la función proporcionada por props para manejar el inicio de sesión
                navigate('/'); // Redirige a la ruta raíz o cualquier otra ruta que desees
            } else {
                alert('Credenciales incorrectas');
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            alert('Error al iniciar sesión. Por favor, intenta de nuevo.');
        }
    };

    
};

export default MyLoginPage;
