import React, { useState, useRef } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table'; 
import dotenv from 'dotenv'; 
import './SuscriptorsUserTable.css';
import { useAuthenticated } from 'react-admin';

function SuscriptorsUserTable({ recipients, setRecipients }) {
  
  useAuthenticated(); 
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const tableRef = useRef();

  // Carga las variables de entorno desde el archivo .env
  dotenv.config();
 

  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Nombre', accessor: 'nombre' },
      { Header: 'Username', accessor: 'username' },
      { Header: 'Password', accessor: 'password'},
      { Header: 'Last Access', accessor: 'lastaccess' }, 
      {
        Header: 'Activo',accessor: 'active',
        Cell: ({ value }) => (
          <input
            type="checkbox"
            checked={value === 'YES'}
            readOnly
          />
        ),
      } 
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize }
  } = useTable(
    { columns, data: recipients, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );

   
  const handleDoubleClick = (rowIndex, rowData) => {
    setEditingRowIndex(rowIndex);
    setEditRowData(rowData);
    const editingRow = tableRef.current.querySelector(`.transactions-container .editing`);
    if (editingRow) {
      editingRow.classList.remove('editing');
    }
    const selectedRow = tableRef.current.querySelector(`.transactions-container tr:nth-child(${rowIndex + 1})`);
    if (selectedRow) {
      selectedRow.classList.add('editing');
    }
  };

  const handleInputChange = (e, columnId) => {
    const { type, checked, value } = e.target;
    setEditRowData(prev => ({
      ...prev,
      [columnId]: type === 'checkbox' ? (checked ? 'YES' : 'NO') : value
    }));
  };

  /* 
  const handleSave = async (rowIndex) => {
    const updatedData = [...recipients];
    const updatedRow =  editRowData;
    const { id, nombre, username,password, lastaccess, active } = updatedRow;
    
 
    try { 

      const response = await axios.put(`http://${apiHost}:${apiPortUser}/api/users/put/${id}`, {
        nombre,
        username,
        password,
        lastaccess,
        active: active === 'YES' ? 'YES' : 'NO'
      });
 
      // Restablecer la renderización y dejar la fila grabada
      if (rowIndex !== undefined) {
        updatedData[rowIndex] = {
          ...updatedRow,
          active: active === 'YES' ? 'YES' : 'NO' // Actualiza el valor en el array actualizado
        };
      }
   
      setEditingRowIndex(null);
      setEditRowData({}); 
    } catch (error) {
      console.error('Error al actualizar el destinatario:', error);
      
    } 
  };

  const handleDelete = async (recipientId) => {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este destinatario?');
    if (confirmDelete) {
      try {
        await axios.delete(`http://${apiHost}:${apiPortUser}/api/user/delete/${recipientId}`);
        // Realizar cualquier otra acción necesaria después de eliminar
        const updatedData = recipients.filter(item => item.id !== recipientId);
        setRecipients(updatedData);
        setEditingRowIndex(null);
        setEditRowData({});
      } catch (error) {
        console.error('Error al eliminar el destinatario:', error); 
      }
    }
  };

  const handleNew = async () => {
    const newData = {
      nombre: "<nombre>",
      username: "<username>",
      password: '<password>'
    };

    try {
      console.log(newData);
      const response = await axios.post(`http://${apiHost}:${apiPortUser}/api/user`, newData);
      // Aquí puedes manejar la respuesta, por ejemplo, actualizar la tabla con los datos nuevos
      console.log('Nuevo destinatario creado:', response.data);
    } catch (error) {
      console.error('Error al crear nuevo destinatario:', error);
      
    }
  };
 */
  return (
    <div className="card">
      <div className="card-header border-0">
        <h3 className="card-title">Lista de Usuarios</h3>
        <div className="card-tools">
          <a href="/" className="btn btn-tool btn-sm">
            <i className="fas fa-download" />
          </a>
          <a href="/" className="btn btn-tool btn-sm">
            <i className="fas fa-bars" />
          </a>
         {/*  <button onClick={handleNew}>Nuevo</button> */}
        </div>
      </div>
      <table {...getTableProps()} ref={tableRef}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th> 
              ))}
             {/*  <td>Acciones</td> */}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onDoubleClick={() => handleDoubleClick(i, row.original)}
                className={editingRowIndex === i ? 'editing' : ''}
              >
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {editingRowIndex === i ? (
                      cell.column.id === 'id' ? (
                        cell.render('Cell')
                      ) : cell.column.id === 'active' ? (
                        <input
                          type="checkbox"
                          checked={editRowData[cell.column.id] === 'YES'}
                          onChange={(e) => handleInputChange(e, cell.column.id)}                          
                        />
                      ) : (
                        <input
                          value={editRowData[cell.column.id] || ''}
                          onChange={(e) => handleInputChange(e, cell.column.id)}                          
                        />
                      )
                    ) : (
                      cell.column.id === 'active' ? (
                        <input
                          type="checkbox"
                          checked={cell.value === 'YES'}
                          readOnly
                        />
                      ) : (
                        cell.render('Cell')
                      )
                    )}
                  </td>                 
                ))
                }
              {/*   <td>
                    <button onClick={() => handleSave(row.original.id)}>Grabar</button>
                    <button onClick={() => handleDelete(row.original.id)}>Delete</button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Anterior
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Siguiente
        </button>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map(size => (
            <option key={size} value={size}>
              Mostrar {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SuscriptorsUserTable;
